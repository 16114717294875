import { useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import topGraphics from 'assets/images/top-pattern.svg';
import bottomGraphics from 'assets/images/bottom-pattern.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import logo from 'assets/images/logo_white.svg';
import useOnboarding from './useOnboarding';
import useLocalizedMarketingPageLinks from '../../hooks/useLocalizedMarketingPageLinks';

const FAQItem = ({ answer, question }: any) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  return (
    <li className="border-b border-swopa-grey-dark pb-3 pt-3 cursor-pointer" onClick={toggle}>
      <div className="flex justify-between items-center w-full">
        <h3 className="font-radikal text-base text-swopa-grey-dark">{question}</h3>
        <ArrowDown
          className={classNames('stroke-swopa-secondary-grey pointer-events-none flex-shrink-0', {
            'rotate-180': open,
          })}
        />
      </div>
      {open && (
        <p
          className="text-sm text-swopa-grey-dark mt-2"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      )}
    </li>
  );
};

export default function Sidebar() {
  const { pathname } = useLocation();
  const category = pathname === '/termin' ? 'appointment-booking' : 'pre-assessment';
  const width = pathname === '/termin' ? 'lg:w-[30%] xxl:w-[20%]' : 'lg:w-[30%]';
  const { data } = useOnboarding({ category });
  const { marketing_page } = useLocalizedMarketingPageLinks();

  return (
    <div
      className={classNames(
        'bg-swopa-primary-dark-blue flex flex-row lg:flex-col relative h-auto lg:h-[calc(100vh-80px)] xl:h-screen overflow-y-auto pt-12 sm:pt-14 pb-32',
        width,
      )}
    >
      <div className="px-6 md:px-8 w-full">
        <a href={marketing_page} className="hidden xl:inline-block mb-16 relative z-10">
          <img src={logo} alt="logo" className="w-[116px]" />
        </a>
        <div className="mb-8">
          <span className="font-radikal text-xs uppercase text-swopa-grey-dark">FAQ</span>
          <h2 className="font-radikal text-xl uppercase text-swopa-grey-dark relative z-10">
            Noch Fragen? Wir sind hier, um zu helfen!
          </h2>
        </div>
        <ul className="relative z-10">
          {data?.map((item) => <FAQItem question={item.question} answer={item.answer} />)}
        </ul>
        <img
          src={topGraphics}
          alt=""
          className="absolute top-0 right-0 w-[70%] md:w-[30%] lg:w-[80%]"
        />
        <img
          src={bottomGraphics}
          alt=""
          className="absolute bottom-0 left-0 w-[40%] md:w-[30%] lg:w-full"
        />
      </div>
    </div>
  );
}
