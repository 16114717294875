import PageContentWrapper from 'components/PageContentWrapper';
import Title from 'components/util/Title';
import Box from 'styles/Box';
import Button from 'styles/Button';
import Input from 'styles/FormikTextarea';
import { Form, Formik } from 'formik';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import usePatient from 'components/Navigation/usePatient';
import http from 'components/util/http';
import { useNotificationContext } from 'components/Notification';
import { useEffect } from 'react';
import { useAuthContext } from 'components/util/useAuth';
import { Localization } from '../localizations/types';
import useLocalizedServicePage from '../hooks/useLocalizedServicePage';

/**
 * ContactForm to contact the company
 * @param setNotification callback to set notification on form submit
 */
function ContactForm({ setNotification }: any) {
  const { data: patient } = usePatient();
  return (
    <Formik
      initialValues={{
        message: '',
      }}
      onSubmit={async (values, { resetForm }) => {
        if (!values.message || values.message === '') {
          setNotification({
            text: 'Sie haben leider noch keine Nachricht geschrieben. Bitte versuchen Sie es erneut.',
            type: 'error',
          });
          return;
        }
        const data = {
          name: patient?.first_name + ' ' + patient?.last_name,
          phone: '',
          email: patient?.email,
          subject: values.message,
        };

        try {
          await http.post('/crm/contacts/', data);
          resetForm();
          setNotification(
            {
              text: 'Ihre Anfrage wurde erfolgreich versendet.',
              type: 'success',
            },
            5000,
          );
        } catch (e: any) {
          const keys = Object.keys(e);
          let error = '';
          if (keys.includes('name')) {
            error = 'Bitte fügen Sie ihren Namen hier ein';
          }
          if (keys.includes('phone_number')) {
            error = 'Telefonnummer ist nicht gültig';
          }
          if (keys.includes('non_field_errors')) {
            error = 'Bitte fügen Sie eine gültige Telefonnummer oder E-Mail-Adresse hinzu';
          }
          if (keys.includes('email')) {
            error = 'Ungültige E-Mail-Adresse';
          }
          setNotification(
            {
              text: 'Ein Fehler ist aufgetreten: ' + error,
              type: 'error',
            },
            5000,
          );
        }
      }}
    >
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <Input
            id="message"
            name="message"
            placeholder="Ihre Nachricht*"
            error={!!(formikProps.touched.message && formikProps.errors.message)}
            className="mb-8 h-32"
          />
          <Button type="submit">Abschicken</Button>
          <div className="mt-2">
            <span className="text-swopa-secondary-grey">*Pflichtfelder</span>
          </div>
        </Form>
      )}
    </Formik>
  );
}

/**
 * Page representing the service section of the user
 * @returns ReactElement
 */
export default function Service() {
  const { showNotification, clearNotification } = useNotificationContext();
  const { telephone_contact, contact_email, address } = useLocalizedServicePage();

  useEffect(() => {
    return () => clearNotification();
  }, [clearNotification]);

  return (
    <PageContentWrapper className="relative">
      <Title title="Service" />
      <div className="bg-[#16DF9F14] rounded px-[11px] py-[16px] mb-[40px]">
        <h2>Unser Telefonservice</h2>
        <p
          className="mt-2 text-[#2D4370]"
          dangerouslySetInnerHTML={{ __html: telephone_contact }}
        ></p>
      </div>
      <h2 className="mb-2 text-h2-mobile">Kontakt</h2>
      <div className="grid grid-cols-1 md:grid-cols-contact gap-y-12">
        <span className="text-swopa-primary-dark-blue">
          Sie haben eine Frage? Wählen Sie eine der unten aufgeführten Kontaktmöglichkeiten und
          unsere Experten melden sich schnellstmöglich bei Ihnen zurück. Wir freuen uns von Ihnen zu
          hören!
        </span>
        <div className="hidden md:block"></div>
        <Box>
          <div className="p-6">
            <ContactForm setNotification={showNotification} />
          </div>
        </Box>
        <div className="flex justify-center items-center">
          <div className="flex flex-col space-y-8">
            <div className="flex flex-col md:flex-row h-fit items-center text-swopa-primary-dark-blue">
              <MailIcon className="md:mr-4 mb-2 md:mb-0" />
              <a
                className="underline text-swopa-primary-dark-blue"
                href={`mailto:${contact_email}`}
              >
                {contact_email}
              </a>
            </div>
            <div className="flex flex-col items-center md:items-start md:flex-row h-fit text-swopa-primary-dark-blue">
              <LocationIcon className="md:mr-4 mt-1 mb-2 md:mb-0" />
              <span
                className="text-center md:text-left"
                dangerouslySetInnerHTML={{ __html: address }}
              ></span>
            </div>
          </div>
        </div>
      </div>
    </PageContentWrapper>
  );
}
