import { createPortal } from 'react-dom';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';

interface DocumentUploadProps {
  onDrop: (files: Array<FileWithPath>) => void;
}

export default function DocumentUpload({ onDrop }: DocumentUploadProps) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
    },
    onDrop,
  });

  const portalDiv = document.getElementById('portal') || document.createElement('div');
  return createPortal(
    <div className="p-16 fixed top-0 left-0 w-full h-screen bg-swopa-accent-green/50 filter backdrop-blur-md text-swopa-primary-white z-50">
      <div
        {...getRootProps()}
        className="border-2 cursor-pointer border-swopa-primary-dark-blue border-dashed flex flex-col justify-center items-center h-full text-swopa-primary-dark-blue"
      >
        <input {...getInputProps()} />
        <UploadIcon className="mb-4" />
        <span className="text-center">Lassen Sie die Datei los, um sie hochzuladen.</span>
        <span className="text-center">(PDF, PNG, JPEG | max. 20MB/Datei)</span>
      </div>
    </div>,
    portalDiv,
  );
}
