import { useState } from 'react';
import classNames from 'classnames';

type FileInputProps = {
  label?: string;
  id: string;
  onChange?: (file: File) => void;
};

export default function FileInput({ label, id, onChange }: FileInputProps) {
  const [file, setFile] = useState<File | null>(null);
  return (
    <div>
      {label && (
        <label className="block mb-2 text-sm font-medium text-swopa-primary-dark-blue">
          {label}
        </label>
      )}
      <label
        htmlFor={id}
        className="flex items-center w-full text-xs text-swopa-secondary-grey border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none h-9"
      >
        <div className="h-full bg-swopa-primary-dark-blue w-fit flex items-center px-4 rounded-l-lg">
          <span className="text-white text-lg">Datei auswählen</span>
        </div>
        <span
          className={classNames('ml-3', {
            'text-swopa-primary-dark-blue': file,
            'text-swopa-secondary-grey': !file,
          })}
        >
          {file ? file.name : 'Keine Datei ausgewählt'}
        </span>
        <input
          id={id}
          className="hidden"
          type="file"
          onChange={(e) => {
            if (e.target.files) {
              setFile(e.target.files[0]);
              if (onChange) onChange(e.target.files[0]);
            }
          }}
        />
      </label>
    </div>
  );
}
