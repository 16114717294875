import logo from 'assets/images/logo_nav.svg';
import useLocalizedMarketingPageLinks from '../../hooks/useLocalizedMarketingPageLinks';

export default function Header() {
  const { marketing_page } = useLocalizedMarketingPageLinks();

  return (
    <header className="px-0 py-4 md:p-8">
      <a href={marketing_page} className="inline-block">
        <img src={logo} alt="logo" className="w-[116px]" />
      </a>
    </header>
  );
}
