import { Prices } from './types';

const prices: Prices = {
  first_appointment_online: {
    de: '75€',
    ch: '129 CHF',
  },
  first_appointment_clinic: {
    de: '109€',
    ch: '129 CHF',
  },
  follow_up_online: {
    de: '39€',
    ch: '99 CHF',
  },
  no_show_fee: {
    de: '19€',
    ch: '49 CHF',
  },
};

export default prices;
