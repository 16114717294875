import { MarketingPageLinksLocalization } from './types';

const marketingPageLinks: MarketingPageLinksLocalization = {
  impressum: {
    de: '/impressum',
    ch: '/impressum',
  },
  privacy_policy: {
    de: '/datenschutz',
    ch: '/datenschutz',
  },
  general_terms: {
    de: '/agb',
    ch: '/agb',
  },
};

export default marketingPageLinks;
