import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
// @ts-ignore
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import TagManager from 'react-gtm-module';
import { Analytics } from '@vercel/analytics/react';

const isProd = process.env.REACT_APP_BRANCH === 'MAIN';

const tagManagerArgs = {
  gtmId: 'GTM-5DFFP78',
  auth: isProd ? 'upD3gClvAMazwXNh24kLJQ' : 'JDOnIeeJhn5MKej9yDRL1Q',
  preview: isProd ? 'env-1' : 'env-52',
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: 'https://d60aedd260764715aae4b384c187c6c8@o1171261.ingest.sentry.io/6265707',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
  enabled: isProd,
});

const queryClient = new QueryClient();
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <BrowserRouter>
    <HelmetProvider>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <App />
          <Analytics mode={isProd ? 'production' : 'development'} />
        </QueryClientProvider>
      </React.StrictMode>
    </HelmetProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
