import { ServicePageLocalization } from './types';

const servicePage: ServicePageLocalization = {
  telephone_contact: {
    de: 'Wir sind <b>Montag bis Freitag</b> von <b>9-17 Uhr</b> und <b>Samstag</b> von<b>9-12 Uhr</b> telefonisch für Sie erreichbar: <b>030 308 073 30</b>',
    ch: 'Wir sind <b>Montag bis Freitag</b> von <b>9:30-18:30 Uhr</b> telefonisch für Sie erreichbar: <b>061 416 08 00</b>',
  },
  contact_email: {
    de: 'service@help.enmedify.com',
    ch: 'support@enmedify.ch',
  },
  address: {
    de: 'SWISS ALPINOPHARMA GmbH<br />Mindspace Office 1031<br />Kurfürstendamm 207-208<br />10719, Berlin<br />Deutschland',
    ch: 'Topmed AG<br />Steinenberg 23<br />4051 Basel<br />Schweiz',
  },
};

export default servicePage;
