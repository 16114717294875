import { useEffect } from 'react';

const loadEtermin = () => {
  const wrapper = document.getElementById('iframe-wrapper');
  const iframe = document.createElement('iframe');
  iframe.style.width = '100%';
  iframe.style.height = '100%';
  iframe.src = window.location.search.substring(1);
  wrapper?.appendChild(iframe);
};

export default function Termin() {
  useEffect(() => {
    loadEtermin();
  }, []);

  return null;
}
